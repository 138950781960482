// This file is part of the Cloudillo Platform.
// Copyright (C) 2024  Szilárd Hajba
//
// Cloudillo is free software: you can redistribute it and/or modify
// it under the terms of the GNU Lesser General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Lesser General Public License for more details.
//
// You should have received a copy of the GNU Lesser General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { atom, useAtom } from 'jotai';
import * as cloudillo from '@cloudillo/base';
// Utility functions //
///////////////////////
export function qs(obj) {
    var str = [];
    for (var f in obj) {
        const val = obj[f];
        if (obj.hasOwnProperty(f) && val !== undefined) {
            str.push(encodeURIComponent(f) + '=' + encodeURIComponent(Array.isArray(val) ? val.join(',') : val));
        }
    }
    return str.join('&');
}
export function parseQS(qs) {
    const p = new URLSearchParams(qs);
    return Object.fromEntries(p.entries());
}
export class ServerError extends Error {
    constructor(code, descr, httpStatus = 400) {
        super(descr);
        this.code = code;
        this.descr = descr;
        this.httpStatus = httpStatus;
    }
}
const authAtom = atom(undefined);
export function useAuth() {
    return useAtom(authAtom);
}
export const apiAtom = atom({ notifications: 0, messages: 0 });
//let apiUrl: string
export function useApi() {
    const [auth] = useAuth();
    const [api, setApi] = useAtom(apiAtom);
    //const [apiUrl, setApiUrl] = React.useState<string | undefined>()
    const abortCtrlRef = React.useRef(null);
    /*
    React.useEffect(function () {
        //console.log('useApi()', api.url, auth)
        if (api.url === undefined) {
            //setApiUrl('')
            ;(async function () {
                if (!window.location.host.startsWith('cl-o.')) {
                    const check = await fetch(`https://${window.location.host}/api/auth/profile`)
                    //console.log('CHECK', check)
                    if (check.ok) {
                        //apiUrl = `https://cl-o.${window.location.host}/api`
                        setApi(api => ({ ...api, url: `https://cl-o.${window.location.host}/api` }))
                    } else {
                        //apiUrl = `https://${window.location.host}/api`
                        setApi(api => ({ ...api, url: `https://${window.location.host}/api` }))
                    }
                } else {
                    //apiUrl = `https://${window.location.host}/api`
                    setApi(api => ({ ...api, url: `https://${window.location.host}/api` }))
                }
            })()
        }

        return function handleAbort() {
            if (abortCtrlRef.current) {
                console.log('FETCH abort()')
                abortCtrlRef.current.abort()
            }
        }
    }, [])
    */
    async function fetchIt(userTag, path, method, data, query = {}) {
        console.log('FETCH', userTag, path, method);
        if (abortCtrlRef.current) {
            console.log('FETCH abort()');
            abortCtrlRef.current.abort();
            abortCtrlRef.current = null;
        }
        abortCtrlRef.current = new AbortController();
        const res = await fetch(`https://${userTag}/api${path}` + (query ? '?' + qs(query) : ''), {
            method,
            headers: {
                'Content-Type': 'application/json',
                ...(auth?.token ? { 'Authorization': `Bearer ${auth?.token}` } : {})
            },
            credentials: 'include',
            body: method != 'GET' ? JSON.stringify(data) : undefined,
            signal: abortCtrlRef.current.signal
        });
        abortCtrlRef.current = null;
        //console.log('RES', res)
        const textRes = await res.text();
        if (res.ok) {
            const j = JSON.parse(textRes);
            if (j && j.error) {
                console.log('FETCH:', j);
                throw new Error(j.error.code);
            }
            else {
                //updateApiState(j)
                return j;
            }
        }
        else {
            try {
                const j = await JSON.parse(textRes);
                console.log('FETCH ERROR', res, j);
                throw new ServerError(j.error.code, j.error.descr, res.status);
            }
            catch (err) {
                throw new ServerError(`SYS-HTTP-${res.status}`, textRes, res.status);
            }
        }
    }
    const apiIface = {
        url: api.url,
        get: async function get(userTag, path, query = {}) {
            return await fetchIt(userTag, path, 'GET', undefined, query);
        },
        post: async function post(userTag, path, data, query = {}) {
            return await fetchIt(userTag, path, 'POST', data, query);
        },
        put: async function put(userTag, path, data, query = {}) {
            return await fetchIt(userTag, path, 'PUT', data, query);
        },
        patch: async function patch(userTag, path, data, query = {}) {
            return await fetchIt(userTag, path, 'PATCH', data, query);
        },
        delete: async function del(userTag, path, query = {}) {
            return await fetchIt(userTag, path, 'DELETE', undefined, query);
        }
    };
    return React.useMemo(() => apiIface, [auth]);
    //return React.useMemo(() => api.url ? apiIface : undefined, [api.url])
    /*
    //return React.useMemo(function () {
    //	return !api.url ? undefined : {
        return !api.url ? undefined : {
            get: async function get<R>(url: string, query: Record<string, string | number | boolean | undefined> = {}): Promise<R> {
                return await fetchIt(url, 'GET', undefined, query)
            },

            post: async function post<R, D = any>(url: string, data: D, query: Record<string, string | number | boolean | undefined> = {}): Promise<R> {
                return await fetchIt(url, 'POST', data, query)
            },

            put: async function put<R, D = any>(url: string, data: D, query: Record<string, string | number | boolean | undefined> = {}): Promise<R> {
                return await fetchIt(url, 'PUT', data, query)
            },

            patch: async function patch<R, D = any>(url: string, data: D, query: Record<string, string | number | boolean | undefined> = {}): Promise<R> {
                return await fetchIt(url, 'PATCH', data, query)
            },

            delete: async function del<R>(url: string, query: Record<string, string | number | boolean | undefined> = {}): Promise<R> {
                return await fetchIt(url, 'DELETE', undefined, query)
            }
        }
    //}
    }, [api.url])
    */
}
export function useCloudillo(appName) {
    const location = useLocation();
    const [auth, setAuth] = useAuth();
    const [fileId, setFileId] = React.useState(undefined);
    const [fileUserTag, setFileUserTag] = React.useState(undefined);
    React.useEffect(function () {
        const [fileUserTag, fileId] = location.hash.slice(1).split(':');
        setFileUserTag(fileUserTag);
        setFileId(fileId);
    }, [location.hash]);
    React.useEffect(function () {
        (async function init() {
            console.log('useCloudillo XXXXXXXXXXXXXXXXXXXXXXXXXX init start');
            try {
                const token = await cloudillo.init(appName);
                setAuth({
                    userTag: cloudillo.userTag,
                    roles: cloudillo.roles,
                    token
                });
            }
            catch (e) {
                console.error('useCloudillo INIT ERROR', e);
            }
            console.log('useCloudillo XXXXXXXXXXXXXXXXXXXXXXXXXX init end');
        })();
    }, [appName]);
    const struct = React.useMemo(() => ({
        token: auth?.token,
        fileUserTag: fileUserTag || '',
        fileId,
        userTag: cloudillo.userTag,
        roles: cloudillo.roles
    }), [auth, fileUserTag, fileId]);
    console.log('cloudillo', struct, fileUserTag, fileId);
    //if (!fileUserTag || !fileId) return
    return struct;
}
export function useCloudilloEditor(appName) {
    const location = useLocation();
    const docId = location.hash.slice(1);
    const cl = useCloudillo(appName);
    const [yDoc, setYDoc] = React.useState(undefined);
    const [provider, setProvider] = React.useState(undefined);
    React.useEffect(function () {
        console.log('cl.token', cl.token, 'docId', docId);
        if (cl.token && docId) {
            (async function initDoc() {
                const { yDoc, provider } = await cloudillo.openYDoc(docId);
                setYDoc(yDoc);
                setProvider(provider);
                console.log('doc', yDoc);
            })();
        }
    }, [cl.token, docId]);
    return {
        ...cl,
        yDoc,
        provider
    };
}
// vim: ts=4
